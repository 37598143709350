var pageIndex = -1;

window.onpopstate = (event: PopStateEvent) => {

    if (!event.state && WinJS.Navigation.location != "") {
        alert("Please use the logout button.");

        //history.pushState({ pageIndex: this.pageIndex }, "#" + "logout");
        return;
    }

    if (pageIndex < event.state.pageIndex)
        forward();
    else
        back();

    console.log("changed");
}

WinJS.Navigation.addEventListener("beforenavigate", (e) => {
    // dont navigate if we are already on the page
    if (WinJS.Navigation.location == e.detail.location)
        e.preventDefault();
});

WinJS.Navigation.addEventListener("navigating", (e) => {

    var back = document.getElementById("back");
    var logout = document.getElementById("logout");

    var host = document.getElementById("host");
    var hostControl = host.winControl;

    if (hostControl && hostControl.unload)
        hostControl.unload();


    WinJS.Promise
        .wrap()
        .then(exit)
        .then(load)
        .then(enter);


    function exit() {
        return WinJS.Promise
            .then(WinJS.UI.Animation.exitPage(host.children))
            .then(() => WinJS.Utilities.disposeSubTree(host))
            .then(() => WinJS.Utilities.empty(host));
    }

    function load() {
        return WinJS.UI.Pages.render(e.detail.location, host);
    }

    function enter() {

        if (back)
            back.style.visibility = WinJS.Navigation.canGoBack ? "visible" : "hidden";
        

        if (typeof e.detail.delta == 'undefined') {
            this.pageIndex = this.pageIndex + 1;
            history.pushState({ pageIndex: this.pageIndex }, "#" + e.detail.location.substr(e.detail.location.lastIndexOf('/') + 1));
        }

        WinJS.UI.Adaptive.processAll();
        return WinJS.UI.Animation.enterPage(host.children);
    }
});

function navigate(url: string, state: any) {
    WinJS.Navigation.navigate(url, state);
};

function back() {
    if (WinJS.Navigation.canGoBack)
        WinJS.Navigation.back();

    this.pageIndex = this.pageIndex - 1
};

function forward() {
    if (WinJS.Navigation.canGoForward)
        WinJS.Navigation.forward();

    this.pageIndex = this.pageIndex + 1
};

function show(template: WinJS.Binding.Template, target: HTMLElement, state: any, init?: () => WinJS.Promise<any> )
{

    return WinJS.Promise
        .wrap()
        .then(exit)
        .then(load)
        .then(init)
        .then(enter);


    function exit() {
        return WinJS.Promise
            .then(WinJS.UI.Animation.exitContent(target))
            .then(() => WinJS.Utilities.disposeSubTree(target))
            .then(() => WinJS.Utilities.empty(target));
    }

    function load() {
        WinJS.UI.Adaptive.processAll();
        return template.render(state, target);
    }

    function enter() {
        return WinJS.UI.Animation.enterContent(target);
    }

}

function hide(target: HTMLElement, state: any) {
    WinJS.Utilities.disposeSubTree(target);
    return WinJS.UI.Animation.exitContent(target);
}

